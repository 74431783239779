import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, useHistory } from 'react-router-dom';
import { Modal } from 'antd';

import ScrollToTop from '../../hoc/ScrollToTop';
import { hideModal } from '../../actions/modal';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Preloader from '../../components/Preloader/Preloader';
import Dashboard from '../Dashboard/Dashboard';
import PageNotFound from '../PageNotFound/PageNotFound';

import styles from './App.module.css';

function App (props) {
  const {
    isFetching,
    showModal,
    modalData,
    hideModal,
  } = props;

  const history = useHistory();

  history.listen(location => {
    const hash = location.hash;
    if (hash) {
      const id = hash.substr(1);
      const element = document.getElementById(id);
      if (element) {
        window.setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        });
      }
    }
  });

  const renderCommonRoutes = () => (
    <Switch>
      <Route path="*" component={PageNotFound}/>
    </Switch>
  );

  const clickOkHandler = () => {
    hideModal();
    modalData.callback?.();
  };

  return (
    <div className={styles.wrapper}>
      {isFetching && <Preloader/>}
      <ScrollToTop />
      <Modal
        wrapClassName={styles.modalWrapper}
        open={showModal}
        centered
        onOk={clickOkHandler}
        cancelButtonProps={{ style: { display: 'none' } }}
        closeIcon={<div className={styles.modalClose} onClick={hideModal} />}
        defaultFooterButtonOrder="primary-center"
      >
        {modalData.body}
      </Modal>
      <Header />
      <Switch>
        <Route exact path="/" component={Dashboard} />
        {renderCommonRoutes()}
      </Switch>
      <Footer />
    </div>
  );
}

const mapStateToProps = state => ({
  showModal: state.modal.show,
  modalData: state.modal.modalData,
});

const mapDispatchToProps = {
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
