import * as _ from 'lodash';
import { notification } from 'antd';

import { isFetching } from './preloader';

export const actionWrapper = (action, errorCallback) => async (dispatch, getState) => {
  try {
    const newState = await action(dispatch, getState);
    if (newState) {
      dispatch(newState);
    }
  } catch (error) {
    dispatch(isFetching(false));
    const response = _.get(error, 'request.response');

    if (!response) {
      return notification.error({
        message: 'Server error',
        placement: 'bottomRight',
        duration: 3,
      });
    }

    try {
      const responseData = JSON.parse(response);

      if (errorCallback) {
        errorCallback(responseData.error, dispatch);
      } else {
        return notification.error({
          message: responseData.error || error.message || 'Server error',
          placement: 'bottomRight',
          duration: 3,
        });
      }
    } catch (parseError) {
      return notification.error({
        message: error.message || 'Server error',
        placement: 'bottomRight',
        duration: 3,
      });
    }
  }
};
