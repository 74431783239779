import { applyMiddleware, combineReducers, createStore } from 'redux';
import { reducer as form } from 'redux-form';
import thunk from 'redux-thunk';

import preloader from './preloader';
import modal from './modal';

const allReducers = combineReducers({
  modal,
  preloader,
  form,
});

const rootReducer = (state, action) => {
  return allReducers(state, action);
};

const middleware = [thunk];

export default createStore(rootReducer, applyMiddleware(...middleware));
