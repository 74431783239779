import { SHOW_MODAL, HIDE_MODAL } from '../actions/modal';

// const initialState = {
//   show: true,
//   modalData: {
//     body: <div>Hello <strong>my friend</strong></div>,
//     callback: () => console.log('===CALLBACK', ),
//   },
// };

const initialState = {
  show: false,
  modalData: {},
};

const modal = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        show: true,
        modalData: action.payload,
      };

    case HIDE_MODAL:
      return {
        ...state,
        show: false,
        modalData: {},
      };

    default:
      return state;
  }
};

export default modal;
