export const advantagesData = [
  {
    title: 'No Need for Recruitment',
    description: 'Just contact us and describe your problem and requirements so that we can offer you specialists with expertise perfect for your project.',
  },
  {
    title: 'Reliable Experts',
    description: 'Our specialists for your software team augmentation are experienced developers, QA engineers, managers, and blockchain experts.',
  },
  {
    title: 'High Quality Based on Motivation',
    description: 'When you love what you’re doing, you will be constantly motivated to work at full strength and give the best result. We love what we’re doing with all our hearts.',
  },
  {
    title: 'Fast Immersion in the Project',
    description: 'Our development specialists are professionals in their respective fields, so you won’t have to spend too much time immersing them in domain peculiarities.',
  },
  {
    title: 'Extensive Talent Pool',
    description: 'Our staff augmentation talent pool has a proven track record in the back-end, front-end, QA, DevOps, blockchain, and other programming technologies and tools.',
  },
  {
    title: 'Part-Time and Full-Time Experts',
    description: 'You can implement software development staff augmentation with our specialists on a part-time or full-time basis.',
  },
  {
    title: 'Smooth Communication',
    description: 'Pleasant and professional communication is vital for every project. Trust us; you’ll love communicating with our technical engineers.',
  },
];