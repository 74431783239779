export const faqs = [
  {
    title: 'Are you a staff augmentation firm?',
    description: 'Yes. However, in addition to offering individual experts for your project, we also provide full-cycle software development services. Thus, you can hire us as a tech team as well.',
  },
  {
    title: 'Do you provide staff augmentation for blockchain-related projects?',
    description: 'Yes, you can hire our specialists for your blockchain project. Moreover, blockchain is among our domain areas, and we have deep expertise in this field.',
  },
  {
    title: 'Do you provide staff augmentation consulting services?',
    description: 'Yes. If you need consulting on what technical experts, stack of programming technologies, functionalities, and other compounds should be engaged in your project, we will gladly listen to your requirements and recommend the most efficient solutions.',
  },
  {
    title: 'For how long do you use the staff augmentation model?',
    description: 'We have being providing software development services for over seven years. Since the day we started and till today, we have delivered nearly 100 projects with of various duration, complexity, and specifics.',
  },
  {
    title: 'Do you offer services only as staff augmentation providers?',
    description: 'No, you can also hire us as a full-cycle dedicated team, which means you won’t have to search for specialists and technical resources elsewhere because you will have it all right here at Soft-Tech.',
  },
  {
    title: 'For how long can I hire your specialists for my project?',
    description: 'It doesn’t matter whether you have a long-term or a short-term project; you can hire our specialists for your project from a few weeks to a few years. Even if our specialist leaves your project for some reason, we will onbooard another expert with identical expertise ASAP.',
  },
  {
    title: 'Can I hire a team for my project?',
    description: 'Yes. You can hire us as a dedicated team with all the technical and non-technical specialists you need for your project.',
  },
];