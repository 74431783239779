import React from 'react';
import moment from 'moment';
import { Link, NavLink } from 'react-router-dom';

import officeImage from '../../assets/images/office.jpeg';
import styles from './Footer.module.css';

const currentYear = moment().format('YYYY');

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.visitBlock}>
        <img className={styles.visitImage} src={officeImage} alt="Office"/>
        <div className={styles.visitAddressBlock}>
          <div className={styles.visitTitle}>
            Visit our office
          </div>
          <div className={styles.visitCity}>
            Stockholm, Sweden
          </div>
          <div className={styles.visitAddress}>
            Norrsken House,<br />
            Birger Jarlsgatan 57 C,<br />
            113 56 Stockholm<br />
            <a href="tel:+46700931035" className={styles.visitPhone}>+46 700 931 035</a>
          </div>
        </div>
      </div>
      <div className={styles.bottomPart}>
        <Link to="/" className={styles.logo} />
        <div className={styles.rightPart}>
          <nav>
            <ul className={styles.menu}>
              <li>
                <div className={styles.copyright}>
                  Copyright {currentYear}
                </div>
              </li>
              <li>
                <NavLink
                  to="/"
                  className={styles.menuLink}
                >
                  Terms & Conditions
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/"
                  className={styles.menuLink}
                >
                  Privacy
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;