import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Header.module.css';

const Header = () => {
  return (
    <header className={styles.header}>
      <Link to="/" className={styles.logo} />
      <a href="/#contact-us" className={styles.contactUsButton}>
        Contact Us
      </a>
    </header>
  );
};

export default Header;
