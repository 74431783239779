import androidLogo from '../assets/images/android.png';
import angularLogo from '../assets/images/angular.png';
import avalancheLogo from '../assets/images/avalanche.png';
import awsLogo from '../assets/images/aws.png';
import azureLogo from '../assets/images/azure.png';
import binanceLogo from '../assets/images/binance.png';
import elasticsearchLogo from '../assets/images/elasticsearch.png';
import ethereumLogo from '../assets/images/ethereum.png';
import flowLogo from '../assets/images/flow.png';
import flutterLogo from '../assets/images/flutter.png';
import googleCloudLogo from '../assets/images/google-cloud.png';
import hardhatLogo from '../assets/images/hardhat.png';
import iosLogo from '../assets/images/ios.png';
import javascriptLogo from '../assets/images/javascript.png';
import kotlinLogo from '../assets/images/kotlin.png';
import laravelLogo from '../assets/images/laravel.png';
import nestLogo from '../assets/images/nest.png';
import nodejsLogo from '../assets/images/nodejs.png';
import phpLogo from '../assets/images/php.png';
import polygonLogo from '../assets/images/polygon.png';
import postgresqlLogo from '../assets/images/postgresql.png';
import pythonLogo from '../assets/images/python.png';
import reactNativeLogo from '../assets/images/react-native.png';
import reactLogo from '../assets/images/react.png';
import redisLogo from '../assets/images/redis.png';
import swiftLogo from '../assets/images/swift.png';
import solidityLogo from '../assets/images/solidity.png';
import symfonyLogo from '../assets/images/symfony.png';
import truffleLogo from '../assets/images/truffle.png';
import typescriptLogo from '../assets/images/typescript.png';
import unitestLogo from '../assets/images/unitest.png';
import vueLogo from '../assets/images/vue.png';
import wordpressLogo from '../assets/images/wordpress.png';

export const mobileTechnologiesLogos = [
  {
    logo: iosLogo,
    alt: 'iOS',
  },
  {
    logo: androidLogo,
    alt: 'android',
  },
  {
    logo: reactNativeLogo,
    alt: 'react native',
  },
  {
    logo: flutterLogo,
    alt: 'flutter',
  },
];

export const cloudTechnologiesLogos = [
  {
    logo: awsLogo,
    alt: 'aws',
  },
  {
    logo: azureLogo,
    alt: 'azure',
  },
  {
    logo: googleCloudLogo,
    alt: 'google cloud',
  },
];

export const webTechnologiesLogos = [
  {
    logo: nodejsLogo,
    alt: 'node.js',
  },
  {
    logo: reactLogo,
    alt: 'react',
  },
  {
    logo: angularLogo,
    alt: 'angular',
  },
  {
    logo: vueLogo,
    alt: 'vue.js',
  },
  {
    logo: nestLogo,
    alt: 'nest',
  },
  {
    logo: redisLogo,
    alt: 'redis',
  },
  {
    logo: postgresqlLogo,
    alt: 'PostgreSQL',
  },
  {
    logo: elasticsearchLogo,
    alt: 'elasticsearch',
  },
  {
    logo: typescriptLogo,
    alt: 'TypeScript',
  },
  {
    logo: symfonyLogo,
    alt: 'symfony',
  },
  {
    logo: laravelLogo,
    alt: 'laravel',
  },
  {
    logo: wordpressLogo,
    alt: 'wordpress',
  },
  {
    logo: phpLogo,
    alt: 'php',
  },
  {
    logo: javascriptLogo,
    alt: 'JavaScript',
  },
  {
    logo: pythonLogo,
    alt: 'python',
  },
  {
    logo: swiftLogo,
    alt: 'swift',
  },
  {
    logo: kotlinLogo,
    alt: 'kotlin',
  },
  {
    logo: solidityLogo,
    alt: 'solidity',
  },
];

export const blockchainTechnologiesLogos = [
  {
    logo: solidityLogo,
    alt: 'solidity',
  },
  {
    logo: truffleLogo,
    alt: 'truffle',
  },
  {
    logo: polygonLogo,
    alt: 'polygon',
  },
  {
    logo: ethereumLogo,
    alt: 'ethereum',
  },
  {
    logo: hardhatLogo,
    alt: 'hardhat',
  },
  {
    logo: avalancheLogo,
    alt: 'avalanche',
  },
  {
    logo: unitestLogo,
    alt: 'unitest',
  },
  {
    logo: binanceLogo,
    alt: 'binance',
  },
  {
    logo: flowLogo,
    alt: 'flow',
  },
];