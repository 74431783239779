import React from 'react';
import { Collapse } from 'antd';

import styles from './Accordion.module.css';

const Panel = Collapse.Panel;

const Accordion = (props) => {
  const { title, description } = props;

  return (
    <Collapse className={styles.accordion}>
      <Panel className={styles.title} header={title}>
        <p>
          {description}
        </p>
      </Panel>
    </Collapse>
  );
};

export default Accordion;
