import photo1 from '../assets/images/photo-1.jpg';
import photo2 from '../assets/images/photo-2.jpg';
import photo3 from '../assets/images/photo-3.jpg';
import photo4 from '../assets/images/photo-4.jpg';
import photo5 from '../assets/images/photo-5.jpg';
import photo6 from '../assets/images/photo-6.jpg';
import photo7 from '../assets/images/photo-7.jpg';
import photo8 from '../assets/images/photo-8.jpg';
import photo9 from '../assets/images/photo-9.jpg';
import photo10 from '../assets/images/photo-10.jpg';
import photo11 from '../assets/images/photo-11.jpg';
import photo12 from '../assets/images/photo-12.jpg';
import photo13 from '../assets/images/photo-13.jpg';

export const testimonialsData = [
  {
    name: 'Laine Kelly',
    position: 'Owner and Creative Director of Tie Dye Ninjas.',
    photo: photo1,
    testimonial: `Working with Soft-Tech has been such a delight. The level of effectiveness in each stage of 
      developing the technical and creative side of bringing Tie Dye Ninjas vision to life has been outstanding!<br /><br />
      Vlad was our project manager and we can’t speak more highly about him. He is very thorough and ensured an 
      organized and effective production plan. I would definitely work along side Vlad and the entire OGL team again! 
      Highly recommend Soft-Tech for any of your business development needs!`,
  },
  {
    name: 'Arthur Sheidt',
    position: 'F&B Manager Verholy Relax Park',
    photo: photo2,
    testimonial: `Soft-Tech guided us through the entire development process with attentiveness and clear 
      communication. The efficiency of their workflow allowed us to achieve fast and measurable deliverables.`,
  },
  {
    name: 'Emmanuel Amuzie',
    position: 'Business Operations Manager Jessica’s Concept ',
    photo: photo3,
    testimonial: `I had a rewarding experience working with Soft-Tech. They are a professional group of talents who 
      paid attention to every detail of my project. I will team up with them again in the future.`,
  },
  {
    name: 'Chris Silveira',
    position: 'General Manager GAIN Group of Companies',
    photo: photo4,
    testimonial: `We needed a redesigned website and a mobile app for medical education consultancy services. 
      That's why we reached out to Soft-Tech to assist with the transformation. Highly thorough and meticulous in 
      its project management, the agency impressed us with on-time deliverables and dedication to the project's`,
  },
  {
    name: 'Richard Wistow',
    position: 'Manager Octanise',
    photo: photo5,
    testimonial: `Soft-Tech’s deep knowledge of software development and their skill in managing the project inspire 
    trust. Their team is professional and self-motivated.`,
  },
  {
    name: 'Sarthak Patnaik',
    position: 'CEO Creativexchange.io',
    photo: photo6,
    testimonial: `Big thank you, my team. I had a demo with the client. They are happy with the progress we have made 
      so far. I’m fortunate that such professionals are working on my project.`,
  },
  {
    name: 'Trond Bjorøy',
    position: 'Head of Product Development - Nordics ATPI',
    photo: photo7,
    testimonial: `I am impressed with the team and recommend them as a reputable service provider. I was confident in 
      the evident success of my final product at the very beginning of the cooperation.`,
  },
  {
    name: 'Nathalie Feldman',
    position: 'CEO and Founder FrejaTECH',
    photo: photo8,
    testimonial: `I am going to work with you further on the projects and have already recommended you to my colleagues. 
      And I took 1st place at the scientific conference thanks to your help with the project, so finding an investor 
      is coming.`,
  },
  {
    name: 'Roy Baladi',
    position: 'Head of Marketplace and Communications SmartRecruiters',
    photo: photo9,
    testimonial: `I hired the agency to build a telemedicine app. Soft-Tech asked thoughtful questions, was mindful 
      of details, and was flexible in its approach. I expect a long-term partnership with this organization.`,
  },
  {
    name: 'Oleksandr Shypylenko',
    position: 'CEO and Founder SunCoast Venture',
    photo: photo10,
    testimonial: `Soft-Tech was completely immersed in the project. The company offered the golden mean between price 
      and quality. When we had any questions, we could get in touch anytime with their 24/7 customer support.`,
  },
  {
    name: 'Henry Waterfall-Allen',
    position: 'Managing Director TOAD & Mother\'s Best Child',
    photo: photo11,
    testimonial: `The projects are going well. I’m thrilled with the teams working on them. We got some tight deadlines 
      to meet, which was a bit of concern, but I can’t blame the team; very knowledgeable and experienced. I plan to 
      involve you in future dev projects, for sure.`,
  },
  {
    name: 'Jeremy Jones',
    position: 'Chief Executive Officer Nurse 2 Nurse Staffing',
    photo: photo12,
    testimonial: `Soft-Tech works hard to create your vision! They met all of their deadlines and delivered a product 
      that was better than I had originally planned. I’m impressed by their high quality of work, and we will continue 
      to work with them in the future!`,
  },
  {
    name: 'Vera Danchenko',
    position: 'Product Project Manager Bambu',
    photo: photo13,
    testimonial: `I am the Product Manager at a company that specializes in providing white-label robo-advisory 
      solutions for B2B to help them with financial planning for end-users. This is the third project we are working on 
      with Soft-Tech. The team works transparently, within estimates and deadlines.`,
  },
];