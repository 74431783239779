import React from 'react';
import { Collapse } from 'antd';
import cn from 'classnames';

import styles from './AdvantageCard.module.css';

const Panel = Collapse.Panel;

const AdvantageCard = (props) => {
  const { title, description } = props;

  return (
    <>
      <div className={cn(styles.advantageCard, styles.mobileHidden)}>
        <div className={styles.advantageTitle}>
          {title}
        </div>
        <div className={styles.graySmallFont}>
          {description}
        </div>
      </div>
      <Collapse className={cn(styles.advantageCard, styles.mobileVisible)}>
        <Panel className={styles.advantageTitle} header={title}>
          <p>
            {description}
          </p>
        </Panel>
      </Collapse>
    </>
  );
};

export default AdvantageCard;
