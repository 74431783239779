import React, { useState, useEffect } from 'react';

import styles from './UploadFile.module.css';

const UploadFile = (props) => {
  const {
    accept,
    maxSize,
    onChange,
    placeholder,
    helpText,
    defaultValue,
  } = props;

  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {
    if (defaultValue) {
      const fileName = defaultValue.split('/').pop();
      setSelectedFile([{ name: fileName }]);
    }
  }, [defaultValue]);

  const onChangeHandler = (event) => {
    const { files } = event.target;

    const newSelectedFile = Array.from(files).filter((file) => {
      const ext = file.name.split('.').pop();
      const fileSize = file.size / 1024 / 1024;
      return (!accept || accept.includes(ext)) && fileSize < maxSize;
    });

    if (newSelectedFile.length) {
      setSelectedFile(newSelectedFile[0]);
    }

    onChange(newSelectedFile[0]);
  };

  const onDeleteHandler = () => {
    setSelectedFile(null);
    onChange(null);

    document.querySelector('#fileUpload').value = null;
  };

  const renderFieldContent = () => {
    if (!selectedFile) {
      return (
        <label htmlFor="fileUpload" className={styles.label}>
          {placeholder}
        </label>
      );
    }

    return (
      <>
        <label htmlFor="fileUpload" className={styles.label} />
        <div className={styles.selectedFile}>
          <div className={styles.fileName}>
            {selectedFile.name}
          </div>
          <div
            onClick={onDeleteHandler}
            className={styles.deleteButton}
          />
        </div>
      </>
    );
  };

  return (
    <div className={styles.wrapper}>
      {renderFieldContent()}
      <input
        id="fileUpload"
        type="file"
        accept={accept}
        className={styles.input}
        onChange={onChangeHandler}
      />
      <div className={styles.help}>
        {helpText}
      </div>
    </div>
  );
};

export default UploadFile;
