import React from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { required, email } from 'redux-form-validators';

import { Input, Checkbox } from '../FormsControls/FormControls';
import UploadFile from '../UploadFile/UploadFile';

import styles from './ContactUsForm.module.css';

const maxFileSize = 5;

export const formName = 'contactUsForm';

const ContactUsForm = (props) => {
  const { dispatch, handleSubmit } = props;

  const uploadFileHandler = (file) => {
    dispatch(change(formName, 'file', file));
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form} id="contact-us">
      <div className={styles.fields}>
        <Field
          className={styles.input}
          component={Input}
          name="name"
          validate={[required({ message: 'Required field *' })]}
          placeholder="Name *"
        />
        <Field
          className={styles.input}
          component={Input}
          name="company"
          placeholder="Company"
        />
        <Field
          className={styles.input}
          component={Input}
          name="email"
          validate={[
            required({ message: 'Required field *' }),
            email({ message: 'Please enter an email address' }),
          ]}
          placeholder="Email *"
        />
        <Field
          className={styles.input}
          component={Input}
          name="message"
          placeholder="Message"
        />
        <UploadFile
          onChange={uploadFileHandler}
          maxSize={maxFileSize}
          buttonText="Upload"
          helpText={`Upload or drag & drop files (up to ${maxFileSize} MB)`}
          placeholder="Attach file"
        />
      </div>
      <div className={styles.conditions}>
        *I give my consent to processing the above personal data under the terms and conditions of Soft-Tech Privacy
        Policy.
      </div>
      <Field
        wrapper_class={styles.checkbox}
        component={Checkbox}
        name="nda"
        label="I’d like to sign an NDA with Soft-Tech"
      />
      <button className={styles.submit}>Contact us</button>
    </form>
  );
};

export default reduxForm({
  form: formName,
})(ContactUsForm);
