import _ from 'lodash';

import { contactUsAPI } from '../api/contactUs';
import { actionWrapper } from './actionWrapper';

export const contactUsSubmit = (contactUsData, callback) => actionWrapper(async (dispatch) => {
  const formData = new FormData();

  _.forEach(contactUsData, (value, field) => {
    formData.append(field, value);
  });

  if (contactUsData.file) {
    formData.set('file', contactUsData.file);
  }

  await contactUsAPI.send(formData);
  callback?.();
});