import React, { useMemo, useState, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import cn from 'classnames';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2';

import styles from './FormControls.module.css';
import 'react-phone-input-2/lib/style.css';

export const FormControl = (props) => {
  const {
    label,
    meta,
    children,
    helpText,
    wrapperClassName,
    withErrorSign,
  } = props;

  const labelUp = (e) => {
    e.target.parentNode.querySelector('.form_control').focus();
  };

  return (
    <div className={cn(styles.formGroup, wrapperClassName)}>
      <div className="input_wrapper">
        {label && (
          <label
            className={`label ${
              meta.active || meta.dirty || meta.initial || meta.initial === 0 ? 'active' : ''
            }`}
            onClick={labelUp}
          >
            {label}
          </label>
        )}

        {children}
      </div>

      {helpText && (
        <div className={cn(styles.input_help, 'help_text')}>{helpText}</div>
      )}

      {meta.touched && meta.error && (
        <div className={cn(styles.validationMessage, 'validation_message')}>{meta.error}</div>
      )}
      {withErrorSign && meta.touched && meta.error && <span className="validation_status_error" />}
      {meta.visited && meta.valid && <span className="validation_status_ok" />}
    </div>
  );
};

export const Input = (props) => {
  const { input, meta, placeholder, type } = props;

  const validationClass = meta.touched && meta.error ? 'validation_error' : 'validation_ok';

  return (
    <FormControl {...props}>
      <input
        {...input}
        type={type}
        placeholder={placeholder}
        className={`form_control ${props.className} ${validationClass}`}
      />
    </FormControl>
  );
};

export const AutocompleteInput = (props) => {
  const { input: { value, onChange }, placeholder, options, className } = props;
  const [open, setOpen] = useState(false);

  const filterHandler = (options, params) => {
    const inputValue = params.inputValue.toLowerCase();

    if (inputValue) {
      return options.filter(option => option.title.toLowerCase().indexOf(inputValue) === 0);
    }

    return [];
  };

  const inputChangeHandler = (event, value) => {
    onChange(value);
    setOpen(!!value.length);
  };

  return (
    <FormControl {...props}>
      <div className={styles.autocompleteWrapper}>
        <Autocomplete
          freeSolo
          disableClearable
          inputValue={value}
          options={options}
          onInputChange={inputChangeHandler}
          filterOptions={filterHandler}
          onClose={() => setOpen(false)}
          open={open}
          getOptionLabel={option => option.title}
          getOptionSelected={(option, value) => option.title === value.title}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={placeholder}
              InputProps={{ ...params.InputProps, className, disableUnderline: true }}
            />
          )}
        />
      </div>
    </FormControl>
  );
};

const SelectWrapper = (props) => {
  const { input, meta, placeholder, children } = props;

  return (
    <FormControl {...props}>
      <select
        {...input}
        className={cn('form_control', props.className, {
          validation_error: meta.visited && meta.error,
          validation_ok: meta.visited && !meta.error,
        })}
      >
        {placeholder && <option value="" disabled hidden>{placeholder}</option>}
        <option/>
        {children}
      </select>
    </FormControl>
  );

};

export const SelectInput = (props) => (
  <SelectWrapper {...props}>
    {props.options && props.options.map((item, index) => (
      <option value={item} key={index}>
        {item}
      </option>
    ))}
  </SelectWrapper>
);

export const SelectIdName = (props) => (
  <SelectWrapper {...props}>
    {props.options && props.options.map((item, index) => (
      <option value={item.id} key={index}>
        {item.name}
      </option>
    ))}
  </SelectWrapper>
);

export const MonthSelect = (props) => {
  const options = useMemo(() => {
    const months = moment.monthsShort();

    return months.reduce((options, name, index) => {
      options.push({ id: index + 1, name });
      return options;
    }, []);
  }, []);

  return SelectIdName({ ...props, options });
};

export const YearSelect = (props) => {
  const options = useMemo(() => {
    const currentYear = +moment().format('YYYY');

    const options = [];
    for (let i = currentYear; i >= 1900; i--) {
      options.push(i);
    }
    return options;
  }, []);

  return SelectInput({ ...props, options });
};

export const Textarea = (props) => {
  const { input, meta, ...restProps } = props;

  return (
    <FormControl {...props}>
      <textarea
        {...input}
        {...restProps}
        className={cn(
          props.className || 'form_control',
          props.input_design,
          meta.touched && meta.error ? 'validation_error' : 'validation_ok'
        )}
      />
    </FormControl>
  );
};


export const Checkbox = (props) => {
  const { input, meta, ...restProps } = props;

  return (
    <div className={props.wrapper_class}>
      {props.label && (
        <label
          htmlFor={props.id}
          className={cn(
            styles.input_wrapper_checkbox,
            props.className,
            { active: meta.active || meta.dirty || meta.initial }
          )}
        >
          <input
            {...input}
            {...restProps}
            type="checkbox"
            className={cn(styles.form_control_checkbox, props.input_design, props.className)}
          />
          <div className={styles.checkmark} />
          {props.label}
        </label>
      )}

      {meta.touched && meta.error && <div className="validation_message">{meta.error}</div>}
    </div>
  );
};

export const Phone = (props) => {
  const { input: { onBlur, onChange, value }, className, placeholder = '', meta } = props;

  const [phone, setPhone] = useState(value);
  const [dialCode, setDialCode] = useState();

  useEffect(() => {
    if (value) {
      // onChange(value);
    }
  }, []);

  const onChangeHandler = () => {
    let enteredPhone = phone === dialCode ? '' : phone;
    onChange(enteredPhone);
  };

  const inputProps = {
    className: cn(className, { validation_error: !!meta.touched && meta.error }),
    placeholder,
    onBlur: (props) => {
      onBlur(props);
      onChangeHandler();
    },
  };


  return (
    <div className={styles.phoneWrapper}>
      <FormControl {...props}>
        <PhoneInput
          inputProps={inputProps}
          country={'ua'}
          countryCodeEditable={false}
          value={phone}
          onChange={(phone, phoneParams) => {
            setPhone(phone);
            setDialCode(phoneParams.dialCode);
            onChangeHandler();
          }}
        />
      </FormControl>
    </div>
  );
};
