const siteURL = 'http://soft-tech.dm';

export default {
  siteURL,
  backURL: 'http://soft-tech.dm/',
};




