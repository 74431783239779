export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const showModal = (modalData) => {
  return {
    type: SHOW_MODAL,
    payload: modalData,
  };
};

export const hideModal = () => {
  return { type: HIDE_MODAL };
};


