import React, { useState } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import cn from 'classnames';
import 'slick-carousel/slick/slick.css';

import { contactUsSubmit } from '../../actions/contactUs';
import { showModal } from '../../actions/modal';
import AdvantageCard from '../../components/AdvantageCard/AdvantageCard';
import Accordion from '../../components/Accordion/Accordion';
import LoginForm from '../../components/ContactUsForm/ContactUsForm';
import { advantagesData } from '../../constants/advantages';
import { faqs } from '../../constants/faqs';
import { testimonialsData } from '../../constants/testimonials';
import {
  mobileTechnologiesLogos,
  cloudTechnologiesLogos,
  webTechnologiesLogos,
  blockchainTechnologiesLogos,
} from '../../constants/technologiesLogos';

// import proofImage1 from '../../assets/images/proof-logo-1.png';
// import proofImage2 from '../../assets/images/proof-logo-2.png';
// import proofImage3 from '../../assets/images/proof-logo-3.png';
// import proofImage4 from '../../assets/images/proof-logo-4.png';
// import proofImage5 from '../../assets/images/proof-logo-5.png';
// import proofImage6 from '../../assets/images/proof-logo-6.png';

import caseStudyImage1 from '../../assets/images/case-study-1.png';
import caseStudyImage2 from '../../assets/images/case-study-2.png';
import caseStudyImage3 from '../../assets/images/case-study-3.png';

import styles from './Dashboard.module.css';

const SliderPrevArrow = (props) => (
  <div className={styles.sliderPrev} onClick={props.onClick} />
);

const SliderNextArrow = (props) => (
  <div className={styles.sliderNext} onClick={props.onClick} />
);

const Dashboard = (props) => {
  const { contactUsSubmit, showModal } = props;

  const [slidePage, setSlidePage] = useState(1);
  const [tries, setTries] = useState(0);

  const renderSuccessModalBody = () => (
    <div className={styles.successModalBody}>
      Your message has been sent successfully
    </div>
  );

  const renderThanksModalBody = () => (
    <div className={styles.thanksModalBody}>
      Thanks for contacting us. We’re thrilled to hear from you, and will get back to you ASAP. Have an awesome day! In
      the meantime, we want to invite you to follow us on{' '}
      <a className={styles.purpleFont} href="https://www.linkedin.com/in/dmytrodvurechenskyi/" target="_blank">
        LinkedIn
      </a>.<br />
      Best, Soft-Tech.
    </div>
  );

  const showSuccessModal = () => {
    setTries(tries + 1);
    showModal({
      body: renderSuccessModalBody(),
      callback: () => {
        showModal({
          body: renderThanksModalBody(),
        });
      },
    });
  };

  const onSubmit = (contactUsData) => {
    contactUsSubmit(contactUsData, showSuccessModal);
  };

  const renderTechnologyLogo = (logoData, index) => (
    <img key={index} className={styles.technologyLogo} src={logoData.logo} alt={logoData.alt}/>
  );

  const sliderDataList = () => {
    return testimonialsData.map((testimonialData, index) => (
      <div className={styles.testimonialWrapper} key={index}>
        <div className={styles.testimonialsBlock}>
          <img className={styles.testimonialPhoto} src={testimonialData.photo} alt={testimonialData.name} />
          <div className={styles.testimonialName}>
            {testimonialData.name}
          </div>
          <div className={styles.purpleFont}>
            {testimonialData.position}
          </div>
          <div
            className={styles.graySmallFont}
            dangerouslySetInnerHTML={{ __html: testimonialData.testimonial }}
          />
        </div>
      </div>
    ));
  };

  const renderSlider = () => {
    const sliderConfig = {
      dots: false,
      slidesToShow: 1,
      prevArrow: <SliderPrevArrow />,
      nextArrow: <SliderNextArrow />,
      afterChange: page => setSlidePage(page + 1),
    };

    return (
      <div className={styles.slider}>
        <div className={styles.sliderPage}>
          {slidePage} / {testimonialsData.length}
        </div>
        <Slider
          infinite
          autoplay
          slidesToScroll={1}
          {...sliderConfig}
        >
          {sliderDataList()}
        </Slider>
      </div>
    );
  };

  const renderFallowUs = () => (
    <>
      <div className={styles.followUs}>
        Follow us
      </div>
      <div className={styles.socialLinks}>
        <a
          className={cn(styles.socialLink, styles.linkedIn)}
          href="https://www.linkedin.com/company/10586611"
          target="_blank"
        />
        <a
          className={cn(styles.socialLink, styles.instagram)}
          href="https://www.instagram.com/opengeekslab"
          target="_blank"
        />
        <a
          className={cn(styles.socialLink, styles.twitter)}
          href="https://twitter.com/opengeekslab"
          target="_blank"
        />
        <a
          className={cn(styles.socialLink, styles.dribbble)}
          href="https://dribbble.com/openGeeksLab_"
          target="_blank"
        />
        <a
          className={cn(styles.socialLink, styles.behance)}
          href="https://www.behance.net/openGeeksLab_LLC"
          target="_blank"
        />
      </div>
    </>
  );

  return (
    <div className={styles.wrapper}>
      <section className={styles.bannerSection}>
        <div className={cn(styles.sectionContent, styles.bannerContent)}>
          <h1 className={styles.mainTitle}>
            Empower Your Team with Staff Augmentation
          </h1>
          <div className={cn(styles.graySmallFont, styles.bannerDescription)}>
            Enhancing your project with resource and staff augmentation can solve so many problems! Don’t waste your
            precious time looking for software development professionals all over the world and the internet – check out
            our staff augmentation talent pool.
          </div>
          <a href="/#contact-us" className={styles.contactUsButton}>
            Contact us
          </a>
        </div>
      </section>
      {/*<section className={cn(styles.sectionContent, styles.proofSection)}>*/}
      {/*<div className={styles.title}>*/}
      {/*Proof of Our Quality and Expertise*/}
      {/*</div>*/}
      {/*<div className={styles.proofCompanies}>*/}
      {/*<img className={styles.proofLogo} src={proofImage1} alt="Proof logo"/>*/}
      {/*<img className={styles.proofLogo} src={proofImage2} alt="Proof logo"/>*/}
      {/*<img className={styles.proofLogo} src={proofImage3} alt="Proof logo"/>*/}
      {/*<img className={styles.proofLogo} src={proofImage4} alt="Proof logo"/>*/}
      {/*<img className={styles.proofLogo} src={proofImage5} alt="Proof logo"/>*/}
      {/*<img className={styles.proofLogo} src={proofImage6} alt="Proof logo"/>*/}
      {/*</div>*/}
      {/*</section>*/}
      <section className={cn(styles.sectionContent, styles.expertiseSection)}>
        <div className={styles.title}>
          Our Technical Expertise
        </div>
        <div className={styles.expertiseItems}>
          <div className={cn(styles.graySmallFont, styles.expertiseDescription)}>
            Years of providing software development and staff augmentation services have made us experts in creating all
            kinds of digital solutions for businesses.
          </div>
          <div className={styles.expertiseInfo}>
            Web app development
          </div>

          <div className={styles.expertiseInfo}>
            Mobile app development
          </div>
          <div className={styles.expertiseInfo}>
            UI/UX design
          </div>
          <div className={styles.expertiseInfo}>
            Cloud Engineering services
          </div>
          <div className={styles.expertiseInfo}>
            EVM-based blockchain development
          </div>
          <div className={styles.expertiseInfo}>
            Quality assurance
          </div>
          <div className={styles.expertiseInfo}>
            Dedicated teams
          </div>
          <div className={styles.expertiseInfo}>
            Software consulting
          </div>

          <div className={cn(styles.graySmallFont, styles.expertiseDescription, styles.secondDescription)}>
            If you need a digital solution for your business, just contact us and tell us what problem you need to
            solve. We’ll offer you the best custom solution that also fits your budget and deadlines.
          </div>
          <a href="/#contact-us" className={styles.contactUsButton}>
            Contact us
          </a>
        </div>
      </section>
      <section className={styles.resourceSection}>
        <div className={styles.sectionContent}>
          <div className={styles.title}>
            Resource and Staff Augmentation to Empower Your Team
          </div>
          <div className={styles.graySmallFont}>
            We have a wide range of specialists and technologies that will boost your team’s overall productivity and
            efficiency. Just let us know whom or what you need at the moment.
          </div>
          <div className={cn(styles.title, styles.technologiesTitle)}>
            Technologies We Offer
          </div>
          <div className={cn(styles.graySmallFont, styles.technologiesDescription)}>
            The staff augmentation model we use for delivering software development services across the globe implies
            offering you experts specializing in these programming technologies.
          </div>
          <div className={styles.technologiesFirst}>
            <div className={styles.technologiesBlock}>
              <div className={styles.technologyType}>
                Mobile app development
              </div>
              <div className={styles.technologies}>
                {mobileTechnologiesLogos.map(renderTechnologyLogo)}
              </div>
            </div>
            <div className={styles.technologiesBlock}>
              <div className={styles.technologyType}>
                cloud hosting services
              </div>
              <div className={styles.technologies}>
                {cloudTechnologiesLogos.map(renderTechnologyLogo)}
              </div>
            </div>
          </div>
          <div className={styles.technologiesBlock}>
            <div className={styles.technologyType}>
              Web development
            </div>
            <div className={styles.technologies}>
              {webTechnologiesLogos.map(renderTechnologyLogo)}
            </div>
          </div>
          <div className={styles.technologiesBlock}>
            <div className={styles.technologyType}>
              Blockchanin-based solutions
            </div>
            <div className={cn(styles.technologies, styles.blockchain)}>
              {blockchainTechnologiesLogos.map(renderTechnologyLogo)}
            </div>
          </div>
        </div>
      </section>
      <div className={styles.sectionContent}>
        <section className={styles.specialistsSection}>
          <div className={styles.title}>
            Specialists You Can Hire for Your Project
          </div>
          <div className={styles.graySmallFont}>
            In case you need tech experts specializing in these software design, development, and testing fields, you
            are welcome to hire them for your team augmentation.
          </div>
          <div className={styles.specialistsList}>
            <div className={cn(styles.specialistCard, styles.software)}>
              <div className={styles.specialistTitle}>
                Software Engineers
              </div>
              <div className={styles.graySmallFont}>
                In case you need tech experts specializing in these software design, development, and testing fields,
                you are welcome to hire them for your team augmentation.
              </div>
            </div>
            <div className={cn(styles.specialistCard, styles.architect)}>
              <div className={styles.specialistTitle}>
                Solution Architects
              </div>
              <div className={styles.graySmallFont}>
                In case your team lacks a tech expert who can professionally build the app’s architecture and core
                structure, we have this staff augmentation talent for your project.
              </div>
            </div>
            <div className={styles.stub} />
            <div className={cn(styles.specialistCard, styles.qa)}>
              <div className={styles.specialistTitle}>
                QA Engineers
              </div>
              <div className={styles.graySmallFont}>
                QA experts must be present on every project because they make sure all parts of software function
                flawlessly. Strengthen your team with our quality assurance professionals.
              </div>
            </div>
            <div className={cn(styles.specialistCard, styles.devops)}>
              <div className={styles.specialistTitle}>
                DevOps Specialists
              </div>
              <div className={styles.graySmallFont}>
                If you need a specialist to make the entire development process smooth and deploy the finished product –
                consider our DevOps expert to join your tech team.
              </div>
            </div>
            <div className={cn(styles.specialistCard, styles.blockchainExpert)}>
              <div className={styles.specialistTitle}>
                Blockchain Experts
              </div>
              <div className={styles.graySmallFont}>
                It’s not that easy to find a good and reliable blockchain expert. Luckily, you won’t have to search for
                them. They’re right here, at your service.
              </div>
            </div>
            <div className={cn(styles.specialistCard, styles.cto)}>
              <div className={styles.specialistTitle}>
                CTO-as-a-Service
              </div>
              <div className={styles.graySmallFont}>
                A Chief Technical Officer always knows best what your project needs. Our staff augmentation model gives
                you an opportunity to benefit from our CTO’s expertise.
              </div>
            </div>
            <div className={cn(styles.specialistCard, styles.manager)}>
              <div className={styles.specialistTitle}>
                Project Managers
              </div>
              <div className={styles.graySmallFont}>
                Good management is a great contribution to the project’s success. Hire one of our PMs to turn chaos into
                a flawlessly arranged order with smooth communication and reporting.
              </div>
            </div>
            <div className={cn(styles.specialistCard, styles.analyst)}>
              <div className={styles.specialistTitle}>
                Business Analysts
              </div>
              <div className={styles.graySmallFont}>
                All successful startups and companies started with market analysis and research. Our business analyst
                will give you the most efficient custom recommendations.
              </div>
            </div>
          </div>
        </section>
        <section className={styles.advantagesSection}>
          <div className={styles.title}>
            Why Choose Soft-Tech for Team Augmentation
          </div>
          <div className={styles.advantagesList}>
            <div className={styles.graySmallFont}>
              We have being providing staff augmentation services for over seven years. Such a long period in custom
              software development and almost 100 finished projects have made us experts in empowering big and small
              teams with the required technical skills.
              <div className={styles.secondRow}>
                Here are the main reasons to choose Soft-Tech above other staff augmentation companies:
              </div>
            </div>
            {advantagesData.map((advantageData, index) => (
              <div className={styles.advantageCard} key={index}>
                <AdvantageCard {...advantageData} />
              </div>
            ))}
          </div>
        </section>
        <section className={cn(styles.sectionContent, styles.hireSection)}>
          <div className={styles.title}>
            We are Experts in These Industries
          </div>
          <div className={styles.expertiseItems}>
            <div className={cn(styles.graySmallFont, styles.expertiseDescription)}>
              Over the years of providing staff augmentation services, we gained vast expertise in these specific
              industries.
            </div>
            <div className={styles.expertiseInfo}>
              Transportation and Logistics
            </div>
            <div className={styles.expertiseInfo}>
              Entertainment and Media
            </div>
            <div className={styles.expertiseInfo}>
              Travel and Hospitality
            </div>
            <div className={styles.expertiseInfo}>
              Sports and Fitness
            </div>
            <div className={styles.expertiseInfo}>
              FinTech
            </div>
            <div className={styles.expertiseInfo}>
              Insurance
            </div>
            <div className={styles.expertiseInfo}>
              Healthcare
            </div>
            <div className={styles.expertiseInfo}>
              EdTech
            </div>
            <div className={styles.expertiseInfo}>
              Real Estate
            </div>
            <div className={styles.expertiseInfo}>
              eCommerce
            </div>
            <div className={cn(styles.graySmallFont, styles.expertiseDescription)}>
              We’ve also gained extensive experience in providing staff augmentation services for the
              <span className={styles.purpleFont}> Enterprise</span> field.
              So, in case you need custom apps for business – we can solve this problem for you.
            </div>
            <div className={cn(styles.graySmallFont, styles.expertiseDescription, styles.secondDescription)}>
              If you don’t see your industry or business area in this list, our team augmentation services are still
              perfect for your project because of the deep tech expertise of every specialist!
            </div>
          </div>
        </section>
        <section className={styles.servicesSection}>
          <div className={styles.title}>
            How We Provide Staff Augmentation Services
          </div>
          <div className={styles.specialistsList}>
            <div className={cn(styles.specialistCard, styles.listening)}>
              <div className={styles.specialistTitle}>
                Listening to the client’s requirements
              </div>
              <div className={styles.graySmallFont}>
                We listen to our client’s goals and needs as well as what exact specialists they need for the future or
                ongoing project.
              </div>
            </div>
            <div className={cn(styles.specialistCard, styles.choosing)}>
              <div className={styles.specialistTitle}>
                Choosing specialists for your project
              </div>
              <div className={styles.graySmallFont}>
                We listen to our client’s goals and needs as well as what exact specialists they need for the future or
                ongoing project.
              </div>
            </div>
            <div className={cn(styles.specialistCard, styles.interviewing)}>
              <div className={styles.specialistTitle}>
                Interviewing
              </div>
              <div className={styles.graySmallFont}>
                We listen to our client’s goals and needs as well as what exact specialists they need for the future or
                ongoing project.
              </div>
            </div>
            <div className={cn(styles.specialistCard, styles.contract)}>
              <div className={styles.specialistTitle}>
                Signing the contract
              </div>
              <div className={styles.graySmallFont}>
                We listen to our client’s goals and needs as well as what exact specialists they need for the future or
                ongoing project.
              </div>
            </div>
            <div className={cn(styles.specialistCard, styles.onboarding)}>
              <div className={styles.specialistTitle}>
                Onboarding
              </div>
              <div className={styles.graySmallFont}>
                We listen to our client’s goals and needs as well as what exact specialists they need for the future or
                ongoing project.
              </div>
            </div>
            <div className={cn(styles.specialistCard, styles.laptop)}>
              <div className={styles.specialistTitle}>
                Working on the project until it’s finished
              </div>
              <div className={styles.graySmallFont}>
                We listen to our client’s goals and needs as well as what exact specialists they need for the future or
                ongoing project.
              </div>
            </div>
          </div>
        </section>
        <section className={styles.studiesSection}>
          <div className={styles.title}>
            Case Studies
          </div>
          <div className={cn(styles.graySmallFont, styles.description)}>
            Over the years of providing staff augmentation services, we gained vast expertise in these specific
            industries.
          </div>
          <div>
            <div className={styles.studyRow}>
              <img
                className={styles.caseStudyImage}
                src={caseStudyImage1}
                alt="Blockchain-Based Project Pool"
              />
              <div className={styles.studyInfoBlock}>
                <div className={styles.studyTitle}>
                  Blockchain-Based Project Pool
                </div>
                <div className={styles.purpleFont}>
                  Fintech, eCommerce
                </div>
                <div className={styles.graySmallFont}>
                  For about a year, we’ve collaborated with the client’s in-house team. Together we created several
                  individual products for our client, which are – ICO, mobile cryptocurrency wallet, token price
                  prediction platform, browser extension, NFT collections, marketplace, DEX on Cronos blockchain, and
                  multi-blockchain network lottery.
                </div>
                <a
                  href="https://opengeekslab.com/projects/blockchain-based-project-pool/"
                  target="_blank"
                  className={cn(styles.studyButton, styles.contactUsButton)}
                >
                  View Full Case Study
                </a>
              </div>
            </div>
            <div className={styles.studyRow}>
              <div className={cn(styles.studyInfoBlock, styles.withShadow)}>
                <div className={styles.studyTitle}>
                  DApp On Ethereum
                </div>
                <div className={styles.purpleFont}>
                  eCommerce
                </div>
                <div className={styles.graySmallFont}>
                  We powered up the client’s team with our designers, developers, as well as QA engineers to create a DApp
                  for their outstanding NFT-related idea. Creating high-quality front-end code, ensuring smart contract
                  security, and implementing the client’s idea as a fully-functional DApp were among our main goals.
                </div>
                <a
                  href="https://opengeekslab.com/projects/a-dapp-on-ethereum/"
                  target="_blank"
                  className={cn(styles.studyButton, styles.contactUsButton)}
                >
                  View Full Case Study
                </a>
              </div>
              <img
                className={styles.caseStudyImage}
                src={caseStudyImage2}
                alt="DApp On Ethereum"
              />
            </div>
            <div className={styles.studyRow}>
              <img
                className={styles.caseStudyImage}
                src={caseStudyImage3}
                alt="Moving Service Mobile App"
              />
              <div className={styles.studyInfoBlock}>
                <div className={styles.studyTitle}>
                  Moving Service Mobile App
                </div>
                <div className={styles.purpleFont}>
                  Moving services, Transportation
                </div>
                <div className={styles.graySmallFont}>
                  Our specialists didn’t work on the development process from its very beginning. However, when we started
                  working on this project, our engineers created a digital ecosystem for clients, delivery managers, and
                  drivers. Thus, the client received two mobile applications and a web admin panel with extensive
                  functionality.
                </div>
                <a
                  href="https://opengeekslab.com/projects/shift/"
                  target="_blank"
                  className={cn(styles.studyButton, styles.contactUsButton)}
                >
                  View Full Case Study
                </a>
              </div>
            </div>
            <div className={styles.studyRow}>
              <div className={styles.studyInfoBlock}>
                <div className={styles.graySmallFont}>
                  Get more information about what we can do in terms of software development and staff augmentation
                  services.
                </div>
                <a
                  href="https://opengeekslab.com/our-projects/"
                  target="_blank"
                  className={cn(styles.studyButton, styles.contactUsButton)}
                >
                  View All Projects
                </a>
              </div>
              <div className={cn(styles.studyInfoBlock, styles.bubbles)} />
            </div>
          </div>
        </section>
      </div>
      <section className={styles.testimonialsSection}>
        <div className={styles.title}>
          Testimonials
        </div>
        <div className={styles.graySmallFont}>
          In case you’re still in doubt whether to use our resource and staff augmentation services, read what our
          clients tell about their experience of working with Soft-Tech.
        </div>
        {renderSlider()}
      </section>

      <div className={styles.sectionContent}>
        <section className={styles.faqSection}>
          <div className={styles.title}>
            FAQs
          </div>
          <div className={styles.faqsContent}>
            {faqs.map((faq, index) => (
              <Accordion {...faq} key={index} />
            ))}
          </div>
        </section>
        <section className={styles.tellUsSection}>
          <div className={styles.title}>
            Tell Us What Your Project Needs
          </div>
          <div className={styles.tellUsBlock}>
            <div className={styles.tellUsLeft}>
              <div className={styles.graySmallFont}>
                We offer staff augmentation solutions for industries of different kinds and businesses of all sizes. If
                you need reliable experts in web, mobile, cloud, or blockchain fields, just contact us, and we’ll work
                it out within a few days.
              </div>
              <div className={cn(styles.graySmallFont, styles.simplyFill)}>
                Simply fill out the form or email us at{' '}
                <a className={styles.purpleFont} href="mailto:hej@soft-tech.se" target="_blank">
                  hej@soft-tech.se
                </a>
              </div>
              <div className={styles.mobileHidden}>
                {renderFallowUs()}
              </div>
            </div>
            <LoginForm key={tries} onSubmit={onSubmit} />
            <div className={styles.mobileVisible}>
              {renderFallowUs()}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  contactUsSubmit,
  showModal,
};

export default connect(null, mapDispatchToProps)(Dashboard);